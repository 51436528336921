<template>
<div class="resume-container">
    <div class="header">
        <p class="noselect">Resumo do relatório</p>
        <Chevron class="icon" @click="() => showResume = !showResume" v-bind:class="{ rotateUp: !showResume, rotateDown: showResume }"/>
    </div>
    <hr v-if="showResume"/>
    <div :key="showRows" >
        <div class="box-container top-padding" v-if="showResume" >
            <div class="box" v-for="(key, index) in Object.keys(cards)" :key="index">
                <div class="box-content">
                    <span class="box-number noselect">
                        {{ cards[key] }}
                    </span>
                    <span class="box-text noselect">{{ key }}</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Chevron from '@/assets/icons/chevron-up.svg'

export default {
    props: {
        // cards: Object,
        data: Object,
        reloadResume: Number
    },
    components: { 
        Chevron 
    },    
    data(){
        return {
            showResume: true,
            showRows: false,
            cards: []
        }
    },
    methods: {
        getResume() {
            this.showRows = false
            const isLoading = this.$loading.show()
            this.api.getManagementReportResume(this.data)
                .then(res => {
                    this.cards = res.data
                    this.createRows()
                })
                .catch(err => {
                    this.$toast.error(err.message)
                })
                .finally(() => {
                    isLoading.hide()
                })
        },
        createRows() {
            setTimeout(() => {
                this.showRows = true 
            }, 300);
        }
    },
    watch: {
        reloadResume: {
            handler: function () {
                this.getResume()
            },
            deep: true,
            immediate: true,
        }
    }
}
</script>

<style lang="scss" scoped>
    .resume-container {
        width: 100%;
        height: 100%;
        background: #FFFFFF;
        border: 1px solid #C6CEEB;
        border-radius: 8px;
        padding-bottom: 15px;

        .header {
            width: 95%;
            display: flex;
            margin: 0 auto;
            padding: 20px 0;
            flex-direction: row;
            justify-content: space-between;
            .icon {
                cursor: pointer;
                stroke: var(--neutral-600) !important;
                width: 20px;
            }

            .rotateUp {
                animation: rotateUp .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }

            .rotateDown {
                animation: rotateDown .5s;
                -webkit-animation-fill-mode: forwards;
                -moz-animation-fill-mode: forwards;
                animation-fill-mode: forwards;
            }

            @keyframes rotateUp {
                    0% {
                        transform: rotate(0deg);
                    }
                    100% {
                        transform: rotate(180deg);
                    }
                }

            @keyframes rotateDown {
                0% {
                    transform: rotate(180deg);
                }
                100% {
                    transform: rotate(0deg);
                }
            }
        }

        hr {
            background-color: #D9DFF2 !important;
            width: 95%;
            margin: 0 auto;
        }

        .top-padding {
            padding: 15px 0 5px 0;
        }

        .bottom-padding {
            padding: 5px 0 15px 0;
        }

        .box-container {
            display: flex;
            flex-direction: column;
            width: 95%;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: -15px;

            .biggerBox {
                width: 12.5vw !important;
                height: 120px !important;
                margin-top: 15px !important;
            }

            .biggerBoxContent {
                width: 12.5vw !important;
            }
            .box {
                display: flex;
                flex-direction: column;
                justify-content: start;
                text-align: start;
                
                height: 100px;
                margin: 0 5px;             
                background: #FFFFFF;
                border: 1px solid #C6CEEB;
                box-shadow: 0px 2px 1px rgba(12, 29, 89, 0.04);
                border-radius: 8px;

                margin-top: 15px !important;

                @media(min-width: 1440px){
                    width: 13vw;
                }

                @media(max-width: 1440px){
                    width: 11vw;
                }

                .box-content {
                    width: 150px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    text-align: start;
                    padding: 15px;

                    span {
                        height: 24px;
                        font-family: 'Red Hat Display';
                        font-style: normal;    
                        color: #0C1D59;
                    }

                    .box-number {
                        font-weight: 700;
                        font-size: 18px;
                    }

                    .box-text {
                        font-weight: 500;
                        font-size: 16px;
                    }
                }
            }
        }

    }

    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
</style>